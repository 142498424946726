@import "../../scss/variables";

@function makeLongShadow($length,$angle) {
	$val: 0px 0px transparent;
	@for $i from 1 through $length {
		$val: #{$val}, #{$i}px #{$i*$angle}px #aaa;
	}
	@return $val;
}

.PlayAnimation {
	&__wrp {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
		overflow: hidden;
		z-index: 800;
		background: radial-gradient(#222, #000);
	}
		&__btncnt {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 50%;
		z-index: 1000;
	}
&__button {
	cursor: pointer;
	position: absolute;
	top:15px;
	z-index: 1000;
	font-size: larger;
	left:15px;
	background-color: rgba(205, 205, 205, 0.239);
	padding:15px;
	border-radius: 5px;
	border:none;
	outline: none;
	color:white;
	&:hover {
		background-color: rgba(255, 255, 255, 0.953);
		color:black;
	}
}
	&__button_active{
		background-color: rgba(255, 255, 255, 0.953);
		color:black;
	}
	&__text {
		text-align: center;
		font-family: $fakeflix-font;
		font-size: 12vmin;
		animation: netflixStyle 5s infinite;
		outline: none;
		white-space: nowrap;
	}
}

@keyframes netflixStyle {
	0% {
		text-shadow: makeLongShadow(100,1);
		color: #f3f3f3;
		transform: scale(1.5, 1.5);
	}
	10% {
		text-shadow: makeLongShadow(100,1.5);
		color: #f3f3f3;
		transform: scale(1.5, 1.5);
	}
	15% {
		color:#f3f3f3;
	}
	20% {
		color: $red;
		text-shadow: none;
		transform: scale(1.1, 1.1);
	}
	75% {
		opacity: 1;
	}
	80% {
		opacity: 0;
		color: $red;
		transform: scale(0.85, 0.9);
	}
	100% {
		opacity: 0;
	}
}
